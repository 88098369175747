<template>
  <DataTable v-model:filters="filters" :value="boardList" paginator :rows="10" dataKey="IDX" :loading="loading" :rowsPerPageOptions="[5, 10, 20, 50]" stripedRows selectionMode="single" @rowSelect="onRowSelect">
    <template #header>
      <div class="div_title2">
        <IconField>
          <InputIcon>
            <i class="pi pi-search" />
          </InputIcon>
          <InputText v-model="filters['global'].value" placeholder="검색" size="small" />
        </IconField>
        <Button label="조회" raised size="small" @click="this.search()" />
      </div>
    </template>
    <template #empty > <div class="no-result">데이터 없음</div> </template>
    <template #loading> <div class="no-result">데이터 검색중...</div> </template>
    <span v-if="this.page === 'admin'">
      <Column header="No/등록일" field="IDX">
        <template #body="{ data }">
          <div class="div_center">
            No {{ data.IDX }}<br>
            {{ this.api.getDateByFormat(data.REG_DATE, 'YYYY.MM.DD') }}
          </div>
        </template>
      </Column>
      <Column header="TYPE" field="REG_DATE">
        <template #body="{ data }">
          <div class="flex items-center gap-2">
            <span>{{ data.TYPE }}</span>
          </div>
        </template>
      </Column>
    </span>
    <span v-else-if="this.page === 'tip'">
      <Column header="No" field="IDX">
        <template #body="{ data }">
          <div class="div_center">
            {{ data.IDX }}<br>
          </div>
        </template>
      </Column>
    </span>
    <span v-else>
      <Column header="idx" field="IDX">
        <template #body="{ data }">
          <div class="div_center">
            {{ data.IDX }}<br>
          </div>
        </template>
      </Column>
      <Column field="REG_DATE" header="등록일" >
        <template #body="{ data }">
          <div class="div_center">
            {{ this.api.getDateByFormat(data.REG_DATE, 'YYYY.MM.DD') }}
          </div>
        </template>
      </Column>
    </span>
    <Column :header="this.page === 'market' ? '상품명' : 'TITLE'" field="TITLE" >
      <template #body="{ data }">
        <div class="flex items-center gap-2">
          <span> {{ data.TITLE }}</span>
        </div>
      </template>
    </Column>
    <Column :exportable="false" style="min-width:155px;" v-if="this.page === 'admin' || this.page === 'trade' ">
      <template #body="{ data }">
        <Button icon="pi pi-pencil" outlined rounded @click="click({type:'edit', idx:data.IDX, contentType:data.TYPE})" raised size="small" />
        <Button icon="pi pi-copy" outlined rounded severity="success" @click="this.api.copyClipboard(`https://contents.geek9.kr/${data.TYPE}/${data.IDX}`)" raised size="small" />
        <Button icon="pi pi-trash" outlined rounded severity="danger" @click="click({type:'DELETE_BOARD', idx:data.IDX})" raised size="small" />
      </template>
    </Column>
  </DataTable>
  <!-- <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div> -->
</template>
<script>
import { ref } from 'vue'
import { FilterMatchMode } from '@primevue/core/api'
import router from './router'

export default {
  mounted() {
    this.emitter.on('contentsTab', this.search)
    if(this.page === 'market' || this.page === 'tip' || this.page === 'logistic' ) this.search()
  },  
  unmounted() {
    this.emitter.off('contentsTab', this.search)
  },
  components: {
  },
  props: {
    page:String
  },
  data() {
    return {
      boardList:[],
      contentType:{}
    }
  },
  setup() {
    const PAGE_LIST = {
      admin:'SELECT_BOARD',
      faq:'SELECT_BOARD_SUGGEST',
      tip:'SELECT_BOARD',
      logistic:'SELECT_BOARD',
      market:'SELECT_TRADE',
      trade:'SELECT_TRADE'
    }
    const loading = ref(false)
    // FilterMatchMode.STARTS_WITH
    // FilterMatchMode.CONTAINS
    // FilterMatchMode.IN
    // FilterMatchMode.EQUALS
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      // REG_DATE: { value: null, matchMode: FilterMatchMode.CONTAINS },
      // TYPE: { value: null, matchMode: FilterMatchMode.CONTAINS },
      // TITLE: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    return {
      filters,loading, PAGE_LIST
    }
  },
  methods: {
    async onRowSelect(event) {
      const idx = event.data.IDX
      const link = document.createElement('a')
      link.href = this.api.BACKEND_URL + `/${event.data.TYPE}/` + idx
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
    },
    async search(param) {
      this.loading = true
      let type = undefined
      param === undefined ? type = this.PAGE_LIST[this.page] : type = param.type
      let p = {}
      p.type = type
      if(this.page === 'market' || this.page === 'trade') {
        p.contentType = 'market'
        p.useYn = 1
      } else if(this.page === 'tip' ) {
        p.contentType = 'blog'
        p.useYn = 1
      } else if(this.page === 'logistic' ) {
        p.contentType = 'logistics'
        p.useYn = 1
      }
      await this.api.axiosGql(type, p)
      .then( async(res) => {
        this.boardList = res.data
      }).catch( (err) => {
        console.log(err)
      }).finally( ()=> {
        this.loading = false
      })
    },
    async click(param) {
      const type = param.type
      if(type === 'edit') {
        const idx = param.idx
        if(confirm('수정할까요?')) router.push({name:"content", params:{idx: idx, type:param.contentType}})
      } else if(type === 'DELETE_BOARD') {
        if(confirm('삭제할까요??')) {
          const idx = param.idx
          await this.api.axiosGql(type, {type:type, idx:idx})
          .then( async(res) => {
            if(res.status === 200) {
              this.boardList = []
              this.search()
            }
          }).catch( (err) => {
            console.log(err)
          }).finally( ()=> {
            this.loading = false
            alert('삭제되었습니다')
          })
        }
      }
    }
  }
}
</script>